import {
  watch,
  onBeforeUnmount,
  useConfigStore,
  useDevice,
  useRuntimeConfig,
  useI18n,
  useRouteBaseName,
  useRoute,
  useScroll,
  useNuxtApp,
  useTracker,
} from '#imports';

declare global {
  interface Window {
    tiledesk: any;
    tiledeskSettings: any;
  }
}

export const useLiveChat = () => {
  const runtimeConfig = useRuntimeConfig();
  const config = useConfigStore();
  const device = useDevice();
  const route = useRoute();
  const nuxtApp = useNuxtApp();
  const getRouteBaseName = useRouteBaseName();
  const { locale } = useI18n();
  const { jitsuEvent } = useTracker();

  const isLoading = ref(false);

  const { arrivedState, isScrolling } = useScroll(nuxtApp.ssrContext ? undefined : window, { offset: { bottom: 500 } });

  watch(
    () => arrivedState.bottom,
    (arrivedBottom) => {
      if (arrivedBottom && window.tiledesk) {
        window.tiledesk.show?.();
      }
    },
  );

  watch(
    [() => config.featureFlags?.web_livechat, isScrolling],
    ([chatbotEnabled, isScrolling]) => {
      const showOnMount = ['MY', 'PH', 'TH'].some((country) => isStringEqual(country, config.country!));

      if (chatbotEnabled && !device.isCrawler && (isScrolling || showOnMount) && !isLoading.value) {
        isLoading.value = true;

        const src = `${runtimeConfig.public.chatbotUrl}/widget/launch.js`;
        const projectids = runtimeConfig.public.chatbotProjectIds.split(',').reduce((ids, value) => {
          const [lang, id] = value.split(':');

          if (!lang || !id) {
            return ids;
          }

          ids[lang] = id;

          return ids;
        }, {} as Record<string, string>);

        let projectid = projectids[locale.value];
        let startHidden = false;

        /** @TODO remove. temp condition */
        if (['index', 'flight', 'hotel'].includes(getRouteBaseName(route) ?? '')) {
          if (!config.featureFlags?.web_livechat_homepage || ['zh', 'zh-tw', 'ja', 'ko', 'ru'].includes(locale.value)) {
            return;
          }

          projectid = locale.value === 'id' ? projectids.id : locale.value === 'th' ? projectids.th : projectids.en;

          if (!showOnMount) {
            startHidden = true;
          }
        }

        if (!projectid) {
          return;
        }

        if (window.tiledesk && window.tiledeskSettings?.projectid === projectid && !startHidden) {
          window.tiledesk.show?.();

          return;
        }

        window.tiledeskSettings = {
          projectid,
          showAttachmentButton: false,
          isLogEnabled: false,
          startHidden,
          preChatForm: device.isChrome && !device.isIos && !device.isAirpazWebView,
          preChatFormJson: [
            {
              name: 'userFullname',
              type: 'text',
              mandatory: true,
              label: {
                en: 'Full name',
              },
            },
            {
              name: 'userEmail',
              type: 'text',
              mandatory: true,
              regex:
                "/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/",
              label: {
                en: 'Email',
              },
              errorLabel: {
                en: 'Invalid email address',
              },
            },
            {
              name: 'airpazCode',
              type: 'text',
              regex: /^\d{10,16}$/.source,
              label: {
                en: 'Airpaz Code',
              },
              errorLabel: {
                en: 'Invalid airpaz code',
              },
            },
          ],
        };

        if (window.tiledesk) {
          window.tiledesk.reInit?.();

          return;
        }

        (function (d, s, id) {
          const w = window;
          // eslint-disable-next-line
        var d = document
          // eslint-disable-next-line
        var i = function() {
            // @ts-expect-error
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };
          // @ts-expect-error
          i.q = [];
          // @ts-expect-error
          i.c = function (args) {
            // @ts-expect-error
            i.q.push(args);
          };
          // @ts-expect-error
          w.Tiledesk = i;
          let js;
          // eslint-disable-next-line
          let fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) return;
          // eslint-disable-next-line
        js = d.createElement(s)
          js.id = id;
          // @ts-expect-error
          js.async = true;
          // @ts-expect-error
          js.src = src;
          js.onload = function () {
            const iframe = document.getElementById('tiledeskiframe');

            if (iframe) {
              const style = document.createElement('style');
              style.textContent = '.button-in-msg { white-space: normal !important; }';
              // @ts-expect-error
              iframe.contentDocument.head.appendChild(style);

              if (window.tiledesk) {
                window.tiledesk.on?.('onOpen', () => {
                  jitsuEvent('user-click-button', {
                    object_name: ' live-chat',
                    object_parameter: 'live-chat-button',
                  });
                });
              }
            }
          };
          // @ts-expect-error
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'tiledesk-jssdk');
      }
    },
    {
      immediate: true,
    },
  );

  onBeforeUnmount(() => {
    if (window.tiledesk) {
      window.tiledesk.hide?.();
    }
  });
};
